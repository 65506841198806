import {
  RadioGroup,
  RadioGroupItem,
  Select,
  SplitTextField,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Question as QuestionType,
  ReviewChangeItemType,
} from "api/resources/applications/types"
import clsx from "clsx"
import InputLabel from "components/application/InputLabel"
import Card from "components/shared/Card"
import FileViewer from "components/shared/FileViewer"
import omit from "lodash/omit"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { SLUGS } from "utils/applicationForm"
import { GenderOptions, Title } from "utils/constants"
import countryCodes from "utils/countryCodes"
import { calculateAge } from "utils/helpers"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import AuditField from "./AuditField"
import Question from "./Question"

const useStyles = createUseStyles(theme => ({
  title: {
    width: "130px",
  },
  preview: {
    borderRadius: 4,
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  box: {
    position: "relative",
    width: "172.37px",
    height: "180px",
    background: theme.colors.onSurface[50],
  },
  disablePointerEvents: {
    pointerEvents: "none",
  },
  enablePointerEvents: {
    pointerEvents: "auto",
  },
}))

const PersonalDetails = () => {
  const classes = useStyles()

  const {
    applicationId,
    personalCheckList,
    setPersonalCheckList,
    applicantData: data,
    questionList,
    canTakeActions,
    countries,
    isReviewDisabled,
    reviewList,
  } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step2.slug
  )

  const titleChoices = Object.entries(Title).map(([label, value]) => ({
    label,
    value,
  }))

  // useEffect(() => {
  //   localForage
  //     .getItem<CheckListType>(applicationId)
  //     .then(item => {
  //       if (item && item.personal) {
  //         setPersonalCheckList(item.personal)
  //       }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   if (!isReviewDisabled) {
  //     if (personalCheckList.length > 0) {
  //       console.log("setting personalCheckList", personalCheckList)
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           localForage.setItem(applicationId, {
  //             ...item,
  //             personal: personalCheckList,
  //           })
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     } else {
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           if (item && item.personal) {
  //             delete item.personal
  //             localForage.setItem(applicationId, item)
  //           }
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [personalCheckList, isReviewDisabled])

  const removeCommentFromList = (fieldName: string) => {
    setPersonalCheckList(prevEvents =>
      prevEvents.filter(event => fieldName !== event.fieldName)
    )
  }

  const getCommentFromList = (fieldName: string) =>
    personalCheckList.find(i => i.fieldName === fieldName)?.comment
  const getErrorFromList = (fieldName: string) =>
    personalCheckList.find(i => i.fieldName === fieldName)?.error || ""

  const handleOnChange = (entry: string | null, fieldName: string) => {
    const fieldNameObj = personalCheckList.find(i => i.fieldName === fieldName)
    if (fieldNameObj && typeof entry === "string" && entry.length === 0) {
      removeCommentFromList(fieldName)
      return
    }

    if (personalCheckList.find(i => i.fieldName === fieldName)) {
      if (entry) {
        setPersonalCheckList(prevState =>
          prevState.map(i =>
            i.fieldName === fieldName
              ? omit({ ...i, comment: entry }, "error")
              : i
          )
        )
      }
    } else {
      setPersonalCheckList(prevState => [
        ...prevState,
        {
          itemId: data.id,
          itemType: ReviewChangeItemType.applicationdetail,
          fieldName,
          comment: entry,
        },
      ])
    }
  }

  const permanentSameAsCurrent = data.addresses.every(
    ({ isCurrent, isPermanent }) => isCurrent && isPermanent
  )

  const currentAddress = data.addresses.find(a => a.isCurrent)

  const permanentAddress = data.addresses.find(a => a.isPermanent)

  const currentAddressChanges =
    currentAddress && reviewList.find(e => e.itemId == currentAddress.id)

  const permanentAddressChanges =
    permanentAddress && reviewList.find(e => e.itemId == permanentAddress.id)

  const getAddressComment = (itemId: number | undefined) => {
    if (!itemId) {
      return ""
    }
    personalCheckList.find(i => i.itemId === itemId)?.comment
  }

  const addressOnChange = (entry: string | null, itemId: number) => {
    const itemIdObj = personalCheckList.find(i => i.itemId === itemId)
    if (itemIdObj && typeof entry === "string" && entry.length === 0) {
      setPersonalCheckList(prevEvents =>
        prevEvents.filter(event => itemId !== event.itemId)
      )
      return
    }
    if (itemIdObj) {
      if (entry) {
        setPersonalCheckList(prevState =>
          prevState.map(i =>
            i.itemId === itemId ? omit({ ...i, comment: entry }, "error") : i
          )
        )
      } else {
        setPersonalCheckList(prevEvents =>
          prevEvents.filter(event => itemId !== event.itemId)
        )
      }
    } else {
      setPersonalCheckList(prevState => {
        if (prevState.find(e => e.itemId === itemId)) {
          return prevState.filter(event => itemId !== event.itemId)
        }
        return [
          ...prevState,
          {
            itemId: itemId,
            itemType: ReviewChangeItemType.applicationAddress,
            comment: entry,
          },
        ]
      })
    }
  }

  const permanentResidenceResponse = permanentSameAsCurrent
    ? [
        {
          id: 1,
          additionalAnswer: "",
          option: 1,
        },
      ]
    : [
        {
          id: 2,
          additionalAnswer: "",
          option: 2,
        },
      ]

  const permanentSameAsCurrentQuestion: QuestionType = {
    id: 1,
    text: "Is your permanent address same as your current address?",
    slug: SLUGS.step2.permanentSameAsCurrent.slug,
    questionType: 1,
    category: "step2",
    isRequired: false,
    options: [
      {
        id: SLUGS.step2.permanentSameAsCurrent.options.yes,
        text: "Yes",
        order: 1,
        additionalAnswerRequired: false,
        additionalAnswerType: 1,
      },
      {
        id: SLUGS.step2.permanentSameAsCurrent.options.no,
        text: "No",
        order: 2,
        additionalAnswerRequired: false,
        additionalAnswerType: 1,
      },
    ],
    responses: permanentResidenceResponse,
  }

  const referralResponse = dynamicQuestions.find(
    ({ question: { slug } }) => slug === SLUGS.step2.referralCodeOrEmail.slug
  )?.question.responses[0]?.additionalAnswer

  const referred = dynamicQuestions.find(
    ({ question: { slug } }) => slug === SLUGS.step2.referred.slug
  )?.question
  const referredResponse = referred?.options.find(item =>
    referred.responses.find(res => res.option === item.id)
  )

  let referralMode: "Referral Code" | "Email Address" | null = null
  let referredBy = ""
  if (referralResponse) {
    referralMode = referralResponse.includes("@")
      ? SLUGS.step2.referralMode.options.email
      : SLUGS.step2.referralMode.options.code

    referredBy = referralResponse
  }

  const isOtherGender =
    data.gender && !Object.values(GenderOptions).some(g => g === data.gender)

  return (
    <>
      <Card className="pb-6">
        <Typography className="px-2 pt-2" variant="title3">
          Personal Details
        </Typography>

        {data && (
          <div className="mt-1 flex flex-col gap-3">
            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("photo")}
              hideCheckBox={!canTakeActions}
              slug="photo"
              text={getCommentFromList("photo")}
              onChange={entry => handleOnChange(entry, "photo")}
            >
              <div
                className={clsx(
                  "flex items-center gap-1.5",
                  classes.enablePointerEvents
                )}
              >
                <Typography className="lg:mr-1" variant="strong">
                  Passport Size Photograph
                </Typography>
              </div>
              <div className={classes.enablePointerEvents}>
                {data.photo ? (
                  <FileViewer className={classes.box} url={data.photo}>
                    <img
                      alt="certificate"
                      className={classes.preview}
                      src={data.photo}
                    />
                  </FileViewer>
                ) : (
                  <Typography className="mt-1 text-muted" variant="strong">
                    Photo not uploaded
                  </Typography>
                )}
              </div>
            </AuditField>
            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("first_name")}
              hideCheckBox={!canTakeActions}
              slug="first_name"
              text={getCommentFromList("first_name")}
              onChange={entry => handleOnChange(entry, "first_name")}
            >
              <div>
                <InputLabel label="Name" required />
                <div className="my-1 flex flex-col gap-1 md:flex-row">
                  <Select
                    className="max-w-[130px]"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    label="Title"
                    menuIsOpen={false}
                    options={titleChoices}
                    placeholder=" "
                    value={
                      data.title
                        ? titleChoices.find(c => c.value === data.title)
                        : null
                    }
                  />
                  <TextField
                    className="sm:max-w-[357px]"
                    label="First Name"
                    value={data.firstName}
                    readOnly
                  />
                  <TextField
                    className="sm:max-w-[357px]"
                    label="Last Name"
                    value={data.lastName}
                    readOnly
                  />
                </div>
              </div>
            </AuditField>
            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("date_of_birth")}
              hideCheckBox={!canTakeActions}
              slug="date_of_birth"
              text={getCommentFromList("date_of_birth")}
              onChange={entry => handleOnChange(entry, "date_of_birth")}
            >
              <div>
                <InputLabel label="Date of Birth" required />
                <div className="flex items-center gap-2">
                  <TextField
                    className="sm:max-w-[357px]"
                    type="date"
                    value={data.dateOfBirth || ""}
                    readOnly
                  />
                  {data.dateOfBirth && (
                    <Typography>
                      Age - {calculateAge(data.dateOfBirth)}
                    </Typography>
                  )}
                </div>
              </div>
            </AuditField>
            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("gender")}
              hideCheckBox={!canTakeActions}
              slug="gender"
              text={getCommentFromList("gender")}
              onChange={entry => handleOnChange(entry, "gender")}
            >
              <InputLabel label="Gender" required />
              <div>
                <RadioGroup
                  value={isOtherGender ? GenderOptions.others : data.gender}
                  className="my-1 flex flex-col gap-x-5 gap-y-3 sm:flex-row"
                >
                  <RadioGroupItem
                    readOnly
                    containerClass="flex space-x-1 items-center"
                    value={GenderOptions.male}
                    label="Male"
                  />
                  <RadioGroupItem
                    readOnly
                    containerClass="flex space-x-1 items-center"
                    value={GenderOptions.female}
                    label="Female"
                  />
                  <RadioGroupItem
                    readOnly
                    containerClass="flex space-x-1 items-center"
                    value={GenderOptions.preferNotToDisclose}
                    label="Prefer not to disclose"
                  />
                  {isOtherGender && (
                    <RadioGroupItem
                      readOnly
                      containerClass="flex space-x-1 items-center"
                      value={GenderOptions.others}
                      label="Others"
                    />
                  )}
                </RadioGroup>
              </div>
              {isOtherGender && (
                <TextField
                  className="mt-2"
                  // className="sm:max-w-[357px]"
                  value={data.gender || ""}
                  readOnly
                />
              )}
            </AuditField>

            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("nationality_id")}
              hideCheckBox={!canTakeActions}
              slug="nationality_id"
              text={getCommentFromList("nationality_id")}
              onChange={entry => handleOnChange(entry, "nationality_id")}
            >
              <InputLabel label="Nationality" required />

              <Select
                className="sm:max-w-[357px]"
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                menuIsOpen={false}
                options={countries}
                placeholder=" "
                value={
                  data.nationalityId
                    ? countries.find(c => c.uuid === data.nationalityId)
                    : null
                }
              />
            </AuditField>

            <div
              className={clsx(
                "pe-3 ps-6 sm:px-10 sm:pe-3",
                classes.disablePointerEvents
              )}
            >
              <InputLabel label="Passport Number" />

              <TextField
                className="sm:max-w-[357px]"
                value={data.passportNumber || ""}
                readOnly
              />
            </div>
            <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("national_id_number")}
              hideCheckBox={!canTakeActions}
              slug="national_id_number"
              text={getCommentFromList("national_id_number")}
              onChange={entry => handleOnChange(entry, "national_id_number")}
            >
              <div>
                <InputLabel label="National ID number" required />
                <TextField
                  className="sm:max-w-[357px]"
                  value={data.nationalIdNumber || ""}
                  readOnly
                />
                <Typography
                  className="mt-0.5"
                  color="onSurface.500"
                  variant="smallBody"
                >
                  Please fill your Aadhar Number/ Emirates ID/ Social Security
                  etc.
                </Typography>
              </div>
            </AuditField>
            {/* <AuditField
              disabled={isReviewDisabled}
              error={getErrorFromList("email")}
              hideCheckBox={!canTakeActions}
              slug="email"
              text={getCommentFromList("email")}
              onChange={entry => handleOnChange(entry, "email")}
            > */}
            <div
              className={clsx(
                "pe-3 ps-6 sm:px-10",
                classes.disablePointerEvents
              )}
            >
              <InputLabel label="Email" required />
              <TextField
                className="sm:max-w-[357px]"
                value={data.email}
                readOnly
              />
            </div>
            {/* </AuditField> */}
            <div
              className={clsx(
                "pe-3 ps-6 sm:px-10",
                classes.disablePointerEvents
              )}
            >
              <InputLabel label="Phone (Mobile)" required />
              <SplitTextField
                className="sm:max-w-[357px]"
                selectPosition="start"
                selectProps={{
                  isSearchable: false,
                  getOptionLabel: ({ dialCode }) => dialCode,
                  getOptionValue: ({ dialCode }) => dialCode,
                  placeholder: "+91",
                  value: data.mobilePhoneNumber
                    ? countryCodes.find(
                        c =>
                          c.dialCode ===
                          // We're checking above
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          `+${data.mobilePhoneNumber!.countryCode}`
                      )
                    : null,
                  menuIsOpen: false,
                  options: countryCodes,
                }}
                textFieldProps={{
                  placeholder: "Phone Number",
                  value: data.mobilePhoneNumber
                    ? data.mobilePhoneNumber.number
                    : "",
                  readOnly: true,
                }}
              />
            </div>

            <div
              className={clsx(
                "pe-3 ps-6 sm:px-10",
                classes.disablePointerEvents
              )}
            >
              <InputLabel label="Phone (Home)" />
              <SplitTextField
                className="sm:max-w-[357px]"
                selectPosition="start"
                selectProps={{
                  isSearchable: false,
                  getOptionLabel: ({ dialCode }) => dialCode,
                  getOptionValue: ({ dialCode }) => dialCode,
                  placeholder: "+91",
                  value: data.homePhoneNumber
                    ? countryCodes.find(
                        c =>
                          // We're checking above
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          c.dialCode === `+${data.homePhoneNumber!.countryCode}`
                      )
                    : null,
                  menuIsOpen: false,
                  options: countryCodes,
                }}
                textFieldProps={{
                  value: data.homePhoneNumber
                    ? data.homePhoneNumber.number
                    : "",
                  readOnly: true,
                }}
              />
            </div>

            {/* <div className={clsx("pe-3 ps-6 sm:px-10", classes.disablePointerEvents)}>
              <InputLabel label="Phone (Work)" />
              <SplitTextField
                className="sm:max-w-[357px]"
                selectPosition="start"
                selectProps={{
                  isSearchable: false,
                  getOptionLabel: ({ dialCode }) => dialCode,
                  getOptionValue: ({ dialCode }) => dialCode,
                  placeholder: "+91",
                  value: data.workPhoneNumber
                    ? countryCodes.find(
                        c =>
                          // We're checking above
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          c.dialCode === `+${data.workPhoneNumber!.countryCode}`
                      )
                    : null,
                  menuIsOpen: false,
                  options: countryCodes,
                }}
                textFieldProps={{
                  value: data.workPhoneNumber
                    ? data.workPhoneNumber.number
                    : "",
                  readOnly: true,
                }}
              />
            </div> */}

            <div
              className={clsx(
                "pe-3 ps-6 sm:px-10",
                classes.disablePointerEvents
              )}
            >
              <InputLabel label="WhatsApp Number" />
              <SplitTextField
                className="sm:max-w-[357px]"
                selectPosition="start"
                selectProps={{
                  isSearchable: false,
                  getOptionLabel: ({ dialCode }) => dialCode,
                  getOptionValue: ({ dialCode }) => dialCode,
                  placeholder: "+91",
                  value: data.whatsappPhoneNumber
                    ? countryCodes.find(
                        c =>
                          c.dialCode ===
                          // We're checking above
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          `+${data.whatsappPhoneNumber!.countryCode}`
                      )
                    : null,
                  menuIsOpen: false,
                  options: countryCodes,
                }}
                textFieldProps={{
                  value: data.whatsappPhoneNumber
                    ? data.whatsappPhoneNumber.number
                    : "",
                  readOnly: true,
                }}
              />
            </div>

            <AuditField
              disabled={isReviewDisabled}
              hideCheckBox={!canTakeActions}
              slug="currentAddress"
              text={
                isReviewDisabled
                  ? currentAddressChanges?.comment
                  : getAddressComment(currentAddress?.id)
              }
              onChange={entry =>
                currentAddress ? addressOnChange(entry, currentAddress.id) : {}
              }
            >
              <div className={clsx("pe-3", classes.disablePointerEvents)}>
                <InputLabel label="Current Residence" required />
                <div className="flex flex-col gap-3">
                  <div className="my-1 flex flex-col gap-1.5">
                    <TextField
                      label="Street Address"
                      value={
                        data.currentResidence
                          ? data.currentResidence.streetAddress
                          : ""
                      }
                      readOnly
                    />
                    <div className="flex flex-col gap-1.5 sm:flex-row">
                      <Select
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isSearchable={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label="Country"
                        menuIsOpen={false}
                        value={
                          data.currentResidence
                            ? data.currentResidence.country
                            : null
                        }
                      />

                      <Select
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isSearchable={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label="State"
                        menuIsOpen={false}
                        value={
                          data.currentResidence
                            ? data.currentResidence.state
                            : null
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-1.5 sm:flex-row">
                      <TextField
                        label="City"
                        value={
                          data.currentResidence
                            ? data.currentResidence.city
                            : ""
                        }
                        readOnly
                      />
                      <TextField
                        label="Postal/Zip Code"
                        value={
                          data.currentResidence
                            ? data.currentResidence.pincode
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AuditField>
            <div className="ps-6 sm:px-10">
              <Question
                className={classes.disablePointerEvents}
                data={permanentSameAsCurrentQuestion}
              />
            </div>
            {!permanentSameAsCurrent && (
              <AuditField
                disabled={isReviewDisabled}
                hideCheckBox={false}
                slug="permanentAddress"
                text={
                  isReviewDisabled
                    ? permanentAddressChanges?.comment
                    : getAddressComment(permanentAddress?.id)
                }
                onChange={entry =>
                  permanentAddress
                    ? addressOnChange(entry, permanentAddress.id)
                    : {}
                }
              >
                <div className={classes.disablePointerEvents}>
                  <InputLabel label="Permanent Residence" required />
                  <div className="my-1 flex flex-col gap-1.5">
                    <TextField
                      label="Street Address"
                      value={
                        data.permanentResidence
                          ? data.permanentResidence.streetAddress
                          : ""
                      }
                      readOnly
                    />
                    <div className="flex flex-col gap-1.5 sm:flex-row">
                      <Select
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isSearchable={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label="Country"
                        menuIsOpen={false}
                        value={
                          data.permanentResidence
                            ? data.permanentResidence.country
                            : null
                        }
                      />
                      <Select
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isSearchable={false}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label="State"
                        menuIsOpen={false}
                        value={
                          data.permanentResidence
                            ? data.permanentResidence.state
                            : null
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-1.5 sm:flex-row">
                      <TextField
                        label="City"
                        value={
                          data.permanentResidence
                            ? data.permanentResidence.city
                            : ""
                        }
                        readOnly
                      />
                      <TextField
                        label="Postal/Zip Code"
                        value={
                          data.permanentResidence
                            ? data.permanentResidence.pincode
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </AuditField>
            )}

            {dynamicQuestions.map(({ questionNumber, question }, index) => {
              if (
                question.slug === SLUGS.step2.currentCountryOfResidence.slug
              ) {
                return (
                  <div
                    className={classes.disablePointerEvents}
                    key={questionNumber}
                  >
                    <Question
                      className="pe-3 ps-6 sm:px-10"
                      data={question}
                      key={index}
                    />
                  </div>
                )
              }

              if (question.slug === SLUGS.step2.referred.slug) {
                return (
                  <React.Fragment key={questionNumber}>
                    <Question
                      className={clsx(
                        "pe-3 ps-6 sm:px-10",
                        classes.disablePointerEvents
                      )}
                      data={question}
                      key={index}
                    />
                  </React.Fragment>
                )
              }

              if (
                referredResponse?.text === SLUGS.step2.referred.options.yes &&
                question.slug === SLUGS.step2.referralCodeOrEmail.slug
              ) {
                return (
                  <React.Fragment key={questionNumber}>
                    <Question
                      className={clsx(
                        "pe-3 ps-6 sm:px-10",
                        classes.disablePointerEvents
                      )}
                      data={question}
                      key={index}
                    />
                    {referralMode !== null && (
                      <div
                        className={clsx(
                          "pe-3 ps-6 sm:px-10",
                          classes.disablePointerEvents
                        )}
                      >
                        <InputLabel
                          label={
                            referralMode ===
                            SLUGS.step2.referralMode.options.code
                              ? "Please share the referral code of the referrer"
                              : "Please share the email address of the referrer"
                          }
                        />

                        <TextField
                          type={
                            referralMode ===
                            SLUGS.step2.referralMode.options.email
                              ? "email"
                              : "text"
                          }
                          value={referredBy}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )
              }
              if (question.slug === SLUGS.step2.referralCodeOrEmail.slug)
                return null
              return (
                <Question
                  className={clsx(
                    "pe-3 ps-6 sm:px-10",
                    classes.disablePointerEvents
                  )}
                  data={question}
                  key={questionNumber}
                />
              )
            })}
          </div>
        )}
      </Card>
    </>
  )
}

export default PersonalDetails
